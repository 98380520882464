import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterOutlet } from '@angular/router';
import { CountryISO, NgxIntlTelInputModule, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input-gg';
import { NotificationsService } from './socket/notifications.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, NgxIntlTelInputModule, ReactiveFormsModule, FormsModule],
})
export class AppComponent {
[x: string]: any;

	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
	phoneForm = new FormGroup({
		phone: new FormControl('', [Validators.required])
	});

    /**
     * Constructor
     */
    constructor(private notificationService:NotificationsService) {
        this.notificationService.requestPermission()
    }
}
    export const LAST_SELECTED = {
    lastTheme: localStorage.getItem('lastSelectedTheme') || 'theme-default',
    lastScheme: localStorage.getItem('lastSelectedScheme') || 'light',
    lastLayout: localStorage.getItem('lastSelectedLayout') || 'thin',
}
