import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { StorageMap } from '@ngx-pwa/local-storage'
import { takeUntil, timeout, map, catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpCancelService } from '../services/httpcancel.service';
import { Router } from '@angular/router';
import { CustomSnackbarService } from 'app/shared/components/snackbar/snackbar.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
    private baseURL: string = environment.apiUrl;
    public tokenName: string = 'fde-token';

    private jwtToken: string;
    private noAuthNeededRoutes: Array<string> = [
      "sign-in",
      "auth/create",
      "auth/forgotPassword",
      "auth/reset-password",
    ];
    private defaultTimeout: number = 60 * 5;

    constructor(
      private _localStorage: StorageMap,
      private httpCancelService: HttpCancelService,
      private router: Router,
      private customSnackBarService: CustomSnackbarService
    ) { }

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
      this.jwtToken = localStorage.getItem(this.tokenName) || undefined;
      if (this.noAuthNeededRoutes.indexOf(req.url) > -1) {
        console.log("commint to this -----:::::::::39")
        this.jwtToken = undefined;
      };

      let showLoader = false;
      if (req.headers.has('showLoader') && req.headers.get('showLoader') == 'true') {
        showLoader = true;
        //Need to show spinner - logic here
      }

      // Delete showLoader header from the request to avoid sending it to the server, which is not required
      req = req.clone({
        headers: req.headers.delete('showLoader')
      });

      return this.prepareUrlAndHeaders(req, next, showLoader);
      // if (this.jwtToken) {
      //   return this.prepareUrlAndHeaders(req, next, showLoader);
      // } else {
      //   this.jwtToken = localStorage.getItem(this.tokenName);
      //   return this.prepareUrlAndHeaders(req, next, showLoader);
      //   // return from(this._localStorage.get(this.tokenName))
      //   // .pipe(
      //   //   switchMap((jwtToken: string) => {
      //   //     this.jwtToken = jwtToken;
      //   //     return this.prepareUrlAndHeaders(req, next, showLoader);
      //   //   })
      //   // );
      // }


    }

    private prepareUrlAndHeaders(req: HttpRequest<any>, next: HttpHandler, showLoader: boolean = false): Observable<HttpEvent<any>> {
      let baseUrl: string = this.baseURL;
      if (req.url.indexOf('i18n') !== -1
      || (req.url.indexOf('http://') === 0
      || req.url.indexOf('https://') === 0)
      || req.url.indexOf('api/') !== -1
      || req.url.indexOf('icons') !== -1) {
        baseUrl = '';
      }
      // Only set Authorization header for auth required routes
      const headers: HttpHeaders = this.noAuthNeededRoutes.indexOf(req.url) > -1 ? req.headers : req.headers.set('Authorization', `Bearer ${this.jwtToken}`);
      req = req.clone({
        url: baseUrl + req.url,
        headers: headers
      });
      //Sends the modified request using the next handler and processes the response or errors
      return next
        .handle(req)
        .pipe(
          takeUntil(this.httpCancelService.onCancelPendingRequests()),
          timeout(1000 * this.defaultTimeout),
          map((event) => {
            if (event instanceof HttpResponse) {
              if (showLoader) {
                setTimeout(() => {
                  /** spinner ends after 0.2 seconds */
                  //Need to hide spinner - logic here
                }, 1000 * 0.2);
              }
            }
            return event;
          }),
          catchError((error: HttpErrorResponse) => {
            if (error.status == 401) {
              this.sessionExpire().then((flag) => {
                console.log(flag);
                if (flag) {
                  this.router.navigate(['/sign-in']);
                }
              });
            }
            if(error.status == 403) {
              this.customSnackBarService.showError('Access Denied');
                //Need to show error toastr with message 'you don't have permission' logic here
            }
            if(error.status == 500) {
              this.customSnackBarService.showError('Internal server error');
              //Need to show error toastr with message 'you don't have permission' logic here
          }

            if (showLoader) {
              //Need to hide spinner - logic here
            }
            return throwError(() => error);
          })
        );
    }

    /**
 * This method is responsible for expiring the user session by deleting the authentication token
 * from local storage. It also cancels any pending HTTP requests associated with the session.
 *
 * Upon successful deletion of the token, the method resolves the promise with `true`.
 * If there is an error during the deletion process, the method resolves the promise with `false`.
 */
    public sessionExpire() {
        return new Promise((resolve) => {
            this._localStorage.delete(this.tokenName).subscribe({
                next: () => {
                    this.httpCancelService.cancelPendingRequests();
                    resolve(true);
                },
                error: () => {
                    resolve(false)
                }
            })
        });
    }
  }
