<div class="ng-star-inserted">
    <div class="absolute inset-0">
        <div class="flex h-full flex-auto flex-col items-center justify-center p-6 sm:p-10">
            <div class="w-full max-w-3xl">
                <img src="images/logo/unauthorized-image.jpg" alt="unauthorized">
            </div>
            <div
                class="mt-12 text-center text-4xl font-extrabold leading-tight tracking-tight sm:mt-24 md:text-7xl md:leading-none">
                Oops... 401! </div>
            <div class="text-secondary mt-2 text-center text-lg font-medium tracking-tight md:text-xl">
                You are not authorized to view this page.
             </div><a class="mt-12 font-medium text-primary-500 underline"
                href="/dashboard"> Back to Dashboard </a>
        </div>
    </div>
</div>
