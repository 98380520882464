import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unauthorized',
  standalone:true,
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.css']
})
export class UnauthorizedComponent {

}
