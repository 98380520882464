import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { OrderComponent } from './modules/order/order.component';
import { ExampleComponent } from './modules/admin/example/example.component';
import { TeamListComponent } from './modules/admin/team-list/team-list.component';
import { UnauthorizedComponent } from './modules/admin/unauthorized/unauthorized.component';
import { PageNotFoundComponent } from './modules/admin/page-not-found/page-not-found.component';
import { InternalServerErrorComponent } from './modules/admin/internal-server-error/internal-server-error.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch : 'full', redirectTo: 'dashboard'},

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'example'},




    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes')},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes')},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes')},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes')}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes')},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes')},



        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes')},
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data : {breadcrumb : { label: 'Dashboard',}},
        // resolve: {
        //     initialData: initialDataResolver
        // },
        children: [
            {path: 'example', loadChildren: () => import('app/modules/admin/example/example.routes'),data: { breadcrumb: { label: 'Example' } }},
            {path: 'dashboard', loadChildren: () => import('app/modules/admin/dashboard/dashboard.routes')},
            // {path: 'order', loadChildren: () => import('app/modules/feature/order/order.routes')},
            {path: 'order', loadChildren: () => import('app/modules/order/order.routes'),data: { breadcrumb: { label: 'Order' } }},
            {path: 'search', loadChildren: () => import('app/modules/feature/search/search.routes'),data: { breadcrumb: { label: 'Search' } }},
            {path: 'title-examination', loadChildren: () => import('app/modules/feature/title-examination/title-examination.routes'),data: { breadcrumb: { label: 'Title Examination' } }},
            {path: 'preliminary-report', loadChildren: () => import('app/modules/feature/preliminary-report/preliminary-report.routes'),data: { breadcrumb: { label: 'Preliminary Report' } }},
            {path: 'curative-process', loadChildren: () => import('app/modules/feature/curative-process/curative-process.routes'),data: { breadcrumb: { label: 'Curative Process' } }},
            {path: 'schedule-signing', loadChildren: () => import('app/modules/feature/schedule-signing/schedule-signing.routes'),data: { breadcrumb: { label: 'Schedule Signing' }}},
            {path: 'closing-disclosure', loadChildren: () => import('app/modules/feature/closing-disclosure/closing-disclosure.routes'),data: { breadcrumb: { label: 'Closing Disclosure' }}},
            {path: 'preclosing', loadChildren: () => import('app/modules/feature/preclosing/preclosing.routes'),data: { breadcrumb: { label: 'Preclosing' }}},
            {path: 'signing-process', loadChildren: () => import('app/modules/feature/signing-process/signing-process.routes'),data: { breadcrumb: { label: 'Signing Process' }}},
            {path: 'post-close', loadChildren: () => import('app/modules/feature/post-close/post-close.routes'),data: { breadcrumb: { label: 'Post Close' }}},
            {path: 'funding-authorization', loadChildren: () => import('app/modules/feature/funding-authorization/funding-authorization.routes'),data: { breadcrumb: { label: 'Funding Authorization' }}},
            {path: 'disbursement', loadChildren: () => import('app/modules/feature/disbursement/disbursement.routes'),data: { breadcrumb: { label: 'Disbursement' }}},
            {path: 'recording', loadChildren: () => import('app/modules/feature/recording/recording.routes'),data: { breadcrumb: { label: 'Recording' }}},
            {path: 'policy', loadChildren: () => import('app/modules/feature/policy/policy.routes'),data: { breadcrumb: { label: 'Policy' }}},
            {path: 'role-permission-list', loadChildren: () => import('app/modules/admin/role-list/role.routes'),data: { breadcrumb: { label: 'Roles' }}},
            {path: 'users', loadChildren: () => import('./modules/users/user.routes') , data: { breadcrumb: { label: 'Users' } }},
            {path: 'my-profile', loadChildren: () => import('./modules/profile/profile.routes'), },
            {path: 'rolemodules', loadChildren: () => import('app/modules/admin/role-permission/role-permission.routes'),data: { breadcrumb: { label: 'Role Permission' }}},
            // {path: 'ui', loadChildren: () => import('app/modules/feature/ui/ui.routes')},
            {path: 'dynamic-form', loadChildren: () => import('app/modules/dynamic-form/dynamic-form.routes')},
            {path: 'teams', loadChildren: ()=> import('app/modules/admin/team-list/team.routes'),data: { breadcrumb: { label: 'Teams' } }},
            {path: 'modules', loadChildren: () => import('app/modules/admin/modules/modules.routes'),data: { breadcrumb: { label: 'Module' }}},
            {path: 'ui', loadChildren: () => import('./modules/feature/ui/ui.routes'),data: { breadcrumb: { label: 'Feature' }}},
            {path: 'apps/mailbox', loadChildren: () => import('../app/modules/admin/mailbox/mailbox.routes')},
            {path: 'partner', loadChildren: () => import('app/modules/partner/partner.routes'),data: { breadcrumb: { label: 'Partner' } }},
            {path: 'vendor', loadChildren: () => import('app/modules/vendor/vendor.routes'),data: { breadcrumb: { label: 'vendor' } }},
            {path: 'process', loadChildren: () => import('app/modules/process/process.routes'),data: { breadcrumb: { label: 'Process' } }},
            {path: 'package', loadChildren: () => import('app/modules/package/package.routes'),data: { breadcrumb: { label: 'Package' } }},
            {path: 'workflow', loadChildren: () => import('app/modules/workflows/workflows.routes'),data: { breadcrumb: { label: 'workflow' } }},

        ]
    },

    //wild-card routes

    // unauthorized
    {path  : 'unauthorized' , component: UnauthorizedComponent},

    // page-not-found
    { path: 'page-not-found', component: PageNotFoundComponent },
    { path: '**', redirectTo:'page-not-found' },

    // Internal Server Error
    // {path:'internal-server-error', component: InternalServerErrorComponent}

];
