import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { errorMessage, seterrorMessage } from 'app/core/abilities/ability';
import { AuthService } from 'app/core/auth/auth.service';
import { CustomSnackbarService } from 'app/shared/components/snackbar/snackbar.service';
import { of, switchMap, throwError } from 'rxjs';

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const router: Router = inject(Router);
    const authService = inject(AuthService);
    const customSnackBarService = inject(CustomSnackbarService);

    // Check the authentication status
    return authService
        .check()
        .pipe(
            switchMap((authenticated) => {
                // If the user is not authenticated...
                if (!authenticated) {
                    const urlTree = router.parseUrl(`sign-in`);
                    return of(urlTree);
                }

                if (route.data.permission &&
                    (
                        (Array.isArray(route.data.permission) && route.data.permission.length > 0 && route.data.permission.every((value) => Object.keys(value).length > 0 && Object.hasOwn(value, 'action') && Object.hasOwn(value, 'subject'))) ||
                        (!Array.isArray(route.data.permission) && typeof route.data.permission === 'object' && Object.keys(route.data.permission).length > 0 && Object.hasOwn(route.data.permission, 'action') && Object.hasOwn(route.data.permission, 'subject'))
                    )
                ) {
                    const permissionsArray = Array.isArray(route?.data?.permission) ? route?.data?.permission : [route?.data?.permission];
                    const hasPermission: boolean = permissionsArray.some((permission) => {
                        const { action, subject } = permission;
                        if (authService.ability.can(action, subject)) {
                            return true;
                        }
                    });
                    if (!hasPermission) {
                        router.navigate(['unauthorized']);
                        if (errorMessage) {
                            customSnackBarService.showError(errorMessage, { displayDuration: 0 });
                            seterrorMessage('');
                        }
                    }
                }
                // Allow the access
                return of(true);
            })
        );
};
